//  Core

.text-link {

    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

}



@include media-breakpoint-down(xs) {}

@include media-breakpoint-down(sm) {}