//  Core

.powered-by-openpay {
  height: 24px;
}

.logo-tdc {
  height: 20px;
}

.banco-tdc {
  width: 500px;
}

.numero-tdc {
  width: 300px;
}

.titular-tdc {
  width: 500px;
}

.vencimiento-tdc {
  width: 190px;
}

.cvv-tdc {
  width: 190px;
}

.formas-pago-aceptadas {
  height: 130px;
}

@include media-breakpoint-down(xs) {

  .banco-tdc {
    width: 100%;
  }

  .numero-tdc {
    width: 100%;
  }

  .titular-tdc {
    width: 100%;
  }

  .vencimiento-tdc {
    width: 50%;
  }

  .cvv-tdc {
    width: 50%;
  }
}

@include media-breakpoint-down(sm) {}