// Background

// Neutral

@each $color,
$value in $theme-colors-neutral {
  @include bg-variant(".bg-neutral-#{$color}", $value);
}

// Brands

@each $color,
$value in $brand-colors {
  @include bg-variant(".bg-brand-#{$color}", $value);
}

// Text brands

@each $color,
$value in $brand-colors {
  @include text-emphasis-variant(".text-#{$color}", $value);
}

// Gradient background

.bg-warm-flame {
  background-image: linear-gradient(45deg, #ff9a9e 0%, #fad0c4 99%, #fad0c4 100%) !important;
}

.bg-night-fade {
  background-image: linear-gradient(to top, #a18cd1 0%, #fbc2eb 100%) !important;
}

.bg-sunny-morning {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%) !important;
}

.bg-tempting-azure {
  background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%) !important;
}

.bg-amy-crisp {
  background-image: linear-gradient(120deg, #a6c0fe 0%, #f68084 100%) !important;
}

.bg-heavy-rain {
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%) !important;
}

.bg-mean-fruit {
  background-image: linear-gradient(120deg, #fccb90 0%, #d57eeb 100%) !important;
}

.bg-malibu-beach {
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%) !important;
}

.bg-deep-blue {
  background-image: linear-gradient(120deg, #e0c3fc 0%, #8ec5fc 100%) !important;
}

.bg-ripe-malin {
  background-image: linear-gradient(120deg, #f093fb 0%, #f5576c 100%) !important;
}

.bg-arielle-smile {
  background-image: radial-gradient(circle 248px at center, #16d9e3 0%, #30c7ec 47%, #46aef7 100%) !important;
}

.bg-plum-plate {
  background-image: linear-gradient(135deg, #667eea 0%, #764ba2 100%) !important;
}

.bg-happy-fisher {
  background-image: linear-gradient(120deg, #89f7fe 0%, #66a6ff 100%) !important;
}

.bg-happy-itmeo {
  background-image: linear-gradient(180deg, #2af598 0%, #009efd 100%) !important;
}

.bg-mixed-hopes {
  background-image: linear-gradient(to top, #c471f5 0%, #fa71cd 100%) !important;
}

.bg-strong-bliss {
  background-image: linear-gradient(to right, #f78ca0 0%, #f9748f 19%, #fd868c 60%, #fe9a8b 100%) !important;
}

.bg-grow-early {
  background-image: linear-gradient(to top, #0ba360 0%, #3cba92 100%) !important;
}

.bg-love-kiss {
  background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%) !important;
}

.bg-premium-dark {
  background-image: linear-gradient(to right, #434343 0%, black 100%) !important;
}

.bg-happy-green {
  background-image: linear-gradient(to bottom, #00b09b, #96c93d) !important;
}

.bg-vicious-stance {
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%) !important;
}

.bg-midnight-bloom {
  background-image: linear-gradient(-20deg, #2b5876 0%, #4e4376 100%) !important;
}

.bg-night-sky {
  background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%) !important;
}

.bg-slick-carbon {
  background-image: linear-gradient(to bottom, #323232 0%, #3F3F3F 40%, #1C1C1C 150%), linear-gradient(to top, rgba(255, 255, 255, 0.40) 0%, rgba(0, 0, 0, 0.25) 200%) !important;
  background-blend-mode: multiply;
}

.bg-royal {
  background-image: linear-gradient(to right, #141e30, #243b55) !important;
}

.bg-asteroid {
  background-image: linear-gradient(to right, #0f2027, #203a43, #2c5364) !important;
}

.bg-skim-blue {
  background-image: linear-gradient(135deg, #ABDCFF 10%, #0396FF 100%) !important;
  ;
}

.bg-light-pure {
  background-image: linear-gradient(135deg, #CE9FFC 10%, #7367F0 100%) !important;
  ;
}

.bg-nice-redora {
  background-image: linear-gradient(135deg, #F761A1 10%, #8C1BAB 100%) !important;
  ;
}

.bg-red-lights {
  background-image: linear-gradient(135deg, #F05F57 10%, #360940 100%) !important;
  ;
}

.bg-serious-blue {
  background-image: linear-gradient(135deg, #97ABFF 10%, #123597 100%) !important;
  ;
}

.bg-deep-sky {
  background-image: linear-gradient(135deg, #6B73FF 10%, #000DFF 100%) !important;
  ;
}

.bg-sunrise-purple {
  background-image: linear-gradient(135deg, #3B2667 10%, #BC78EC 100%) !important;
  ;
}

// Composed backgrounds

// .bg-composed-img-1 {
//   background-image: url('../../images/composed-bg/bg1.jpg');
// }

// .bg-composed-img-2 {
//   background-image: url('../../images/composed-bg/bg2.jpg');
// }

// .bg-composed-img-3 {
//   background-image: url('../../images/composed-bg/bg3.jpg');
// }

// .bg-composed-img-4 {
//   background-image: url('../../images/composed-bg/bg4.jpg');
// }

// .bg-composed-img-5 {
//   background-image: url('../../images/composed-bg/bg5.jpg');
// }

// Gray variations backgrounds

.bg-gray-100 {
  background: $gray-100 !important;
}

.bg-gray-200 {
  background: $gray-200 !important;
}

.bg-gray-300 {
  background: $gray-300 !important;
}

.bg-gray-400 {
  background: $gray-400 !important;
}

.bg-gray-500 {
  background: $gray-500 !important;
}