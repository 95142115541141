//  Core

.filtro-periodo {
  width: 25%;
}

@include media-breakpoint-down(xs) {

  .partidas-transacciones {
    display: none;
  }

  .filler-periodo {
    display: none;
  }

  .filtro-periodo {
    width: 100%;
  }
}

@include media-breakpoint-down(sm) {}